import type { ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import type { SyncType } from "o365.pwa.types.ts";
import type { ISystemLookupProgressOptions, ISystemLookupProgressJSON } from 'o365.pwa.modules.client.steps.SystemLookupProgress.ts';
import type { OnBeforeSync, OnAfterSync } from 'o365.pwa.modules.client.steps.DataObjectStepDefinition.ts';

import { StepDefinition } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { GroupStepDefinition, IGroupStepDefinitionOptions } from 'o365.pwa.modules.client.steps.GroupStepDefinition.ts';
import { DataObjectStepDefinition } from 'o365.pwa.modules.client.steps.DataObjectStepDefinition.ts';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { SystemLookupProgress } from 'o365.pwa.modules.client.steps.SystemLookupProgress.ts';

import { getDataObjectById } from 'o365-dataobject';

import 'o365.dataObject.extension.Offline.ts';


export interface IOnBeforeSyncResponse {
    error: Error,
    title: string,
    body: string;
}

export interface IOnAfterSyncResponse {
    error: Error,
    title: string,
    body: string;
}

export type SystemLookupOnBeforeSync = (systemLookupDataObjects: Set<string>, meomory: Object) => Promise<IOnBeforeSyncResponse | void>;
export type SystemLookupOnAfterSync = (meomory: Object, syncProgress: any) => Promise<IOnAfterSyncResponse | void>;

interface ISystemLookupStepDefintionOptions extends IGroupStepDefinitionOptions {
    systemLookups?: Array<string>;
    onBeforeSync?: SystemLookupOnBeforeSync;
    onAfterSync?: SystemLookupOnAfterSync;
    onBeforeDataObjectSync?: OnBeforeSync;
    onAfterDataObjectSync?: OnAfterSync;
}

export class SystemLookupStepDefinition extends GroupStepDefinition {
    public readonly systemLookups?: Array<string>;
    public readonly onBeforeSync?: SystemLookupOnBeforeSync;
    public readonly onAfterSync?: SystemLookupOnAfterSync;
    public readonly onBeforeDataObjectSync?: OnBeforeSync;
    public readonly onAfterDataObjectSync?: OnAfterSync;

    constructor(options: ISystemLookupStepDefintionOptions) {
        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            steps: new Array(),
            vueComponentName: 'SystemLookupProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.SystemLookupProgress.vue');
            },
            subVueComponentsDefinitions: new Array(),
        });

        this.systemLookups = options.systemLookups;
        this.onBeforeSync = options.onBeforeSync;
        this.onAfterSync = options.onAfterSync;
        this.onBeforeDataObjectSync = options.onBeforeDataObjectSync;
        this.onAfterDataObjectSync = options.onAfterDataObjectSync;
    }

    public toRunStepDefinition(): SystemLookupStepDefinition {
        return new SystemLookupStepDefinition({
            stepId: this.stepId,
            title: this.title,
            systemLookups: this.systemLookups,
            onBeforeSync: this.onBeforeSync,
            onAfterSync: this.onAfterSync,
            onBeforeDataObjectSync: this.onBeforeDataObjectSync,
            onAfterDataObjectSync: this.onAfterDataObjectSync,
            dependOnPreviousStep: this.dependOnPreviousStep,
            steps: this.steps
        });
    }

    public generateStepProgress(options?: ISystemLookupProgressOptions | ISystemLookupProgressJSON, syncType?: SyncType): SystemLookupProgress {
        return new SystemLookupProgress({
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback,
            stepsProgress: this.steps.map((step: StepDefinition, index: number) => { return step.generateStepProgress(options?.stepsProgress?.[index], syncType); })
        });
    }

    public async syncOnline(options: ISyncOptions<SystemLookupProgress>): Promise<void> {
        throw new Error('System Lookup Step Definition does not support online sync');
    }

    public async syncOffline(options: ISyncOptions<SystemLookupProgress>): Promise<void> {
        try {
            options.stepProgress.systemLookupHasStarted = true;

            const systemLookupDataObjects = options.syncProgress.customData.systemLookupDataObjects as Set<string> | undefined;

            // ---- On Before Sync ---- //
            if (typeof this.onBeforeSync === 'function') {
                const onBeforeSyncResponse = await this.onBeforeSync(systemLookupDataObjects, options.memory);

                if (typeof onBeforeSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onBeforeSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onBeforeSyncResponse.title, onBeforeSyncResponse.body));

                    return;
                }
            }

            if (systemLookupDataObjects === undefined) {
                return;
            }

            if (this.systemLookups) {
                for (const dataObjectId of this.systemLookups) {
                    systemLookupDataObjects.add(dataObjectId);
                }
            }

            for (const dataObjectId of systemLookupDataObjects) {
                this.steps.push(this.createDataObjectStepDefinition(dataObjectId));
            }

            options.stepProgress.stepsProgress = this.steps.map((step: StepDefinition, index) => step.generateStepProgress(options?.stepsProgress?.[index], 'OFFLINE-SYNC'));

            options.stepProgress.systemLookupHasCompleted = true;

            await super.syncOffline(options);

            // ---- On After Sync ---- //
            if (typeof this.onAfterSync === 'function') {
                const onAfterSyncResponse = await this.onAfterSync(options.memory, options.syncProgress);

                if (typeof onAfterSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onAfterSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onAfterSyncResponse.title, onAfterSyncResponse.body));

                    return;
                }
            }
        } catch (reason) {
            options.stepProgress.errors.push(reason);
            options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', ''));
            options.stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
        }
    }

    public async truncateData(options: ISyncOptions<SystemLookupProgress>): Promise<void> {
        // TODO: Implement
    }

    private createDataObjectStepDefinition(dataObjectId: string): DataObjectStepDefinition {
        const dataObject = getDataObjectById(dataObjectId, 'o365-offline-components');

        if (dataObject === undefined) {
            throw new Error('Failed to find data object in o365-offline-components: ' + dataObjectId);
        }

        if (dataObject.shouldEnableOffline !== true) {
            throw new Error('Data Object is not setup for offline: ' + dataObjectId)
        }

        return new DataObjectStepDefinition({
            stepId: `SystemLookup-${dataObjectId}`,
            title: `System Lookup ${dataObjectId}`,
            appId: 'o365-offline-components',
            dataObjectId,
            onBeforeSync: this.onBeforeDataObjectSync,
            onAfterSync: this.onAfterDataObjectSync
        });
    }
}